const RemoveIcon = () => {
  return (
    <svg viewBox='0 0 18 18' fill='none' xmlnsSvg='http://www.w3.org/2000/svg'>
      <defs />
      <circle id='Ellipse 5' cx='9.000000' cy='9.000000' r='9.000000' fill='#62196D' fillOpacity='1.000000' />
      <path
        id='Vector 42'
        d='M5.14 5.14L12.85 12.85'
        stroke='#FFFFFF'
        strokeOpacity='1.000000'
        strokeWidth='1.000000'
      />
      <path
        id='Vector 43'
        d='M12.85 5.14L5.14 12.85'
        stroke='#FFFFFF'
        strokeOpacity='1.000000'
        strokeWidth='1.000000'
      />
    </svg>
  )
}

export default RemoveIcon
