import petImages from '../components/pets-images'

export const getPetsImages = () => petImages

export const getGameText = (isMainGame: boolean) => ({
  descItem: isMainGame
    ? [
        'Выберите по одной близкой вам',
        'характеристике в каждой строке. Когда все',
        'характеристики выбраны, нажмите',
        'кнопку «Это про меня!»',
      ]
    : [
        'ВЫБЕРИТЕ В КАЖДОЙ СТРОЧКЕ ПО ОДНОЙ',
        'ХАРАКТЕРИСТИКЕ БЛИЗКОЙ ВАШЕМУ ПИТОМЦУ.',
        'КОГДА ВСЕ ХАРАКТЕРИСТИКИ ВЫБРАНЫ,',
        'НАЖМИТЕ «ГОТОВО!»',
      ],

  descItemDesktop: isMainGame
    ? [
        'выберите по одной близкой вам характеристике',
        'в каждой строке. Когда все характеристики',
        'выбраны, нажмите кнопку «Это про меня!»',
      ]
    : [
        'ВЫБЕРИТЕ В КАЖДОЙ СТРОЧКЕ ПО ОДНОЙ ХАРАКТЕРИСТИКЕ',
        'БЛИЗКОЙ ВАШЕМУ ПИТОМЦУ. КОГДА',
        'ВСЕ ХАРАКТЕРИСТИКИ ВЫБРАНЫ, НАЖМИТЕ «ГОТОВО!»',
      ],

  selectsGame: isMainGame
    ? [
        { title: '?', category: 1 },
        { title: '?', category: 2 },
        { title: '?', category: 3 },
        { title: '?', category: 4 },
      ]
    : [
        { title: '?', category: 1 },
        { title: '?', category: 2 },
        { title: '?', category: 3 },
      ],
})
