import clsx from 'clsx'
import s from './style.module.scss'
import { Image } from 'react-bootstrap'
import BankRus from '../../../../shared/assets/images/bank-rus.png'
import useWindowDimensions from '../../../../shared/hooks/useWindowDimensions'

import sberSpasibo from '../../../../shared/download/Rules_SberSpasibo.pdf'
import rules from '../../../../shared/download/Rules.pdf'
import { Link } from 'react-router-dom'
import ym from 'react-yandex-metrika'

const Footer = () => {
  const { width } = useWindowDimensions()
  const isMobile = width <= 1300

  const handleClick = () => {
    ym('reachGoal', 'main_rules_click', { main: { rules: { click: 'Полные плавила' } } })
  }

  return (
    <div className={clsx(s.footer)}>
      <div className='container'>
        {isMobile && <Image src={BankRus} />}

        <div className={s.footer_description}>
          {!isMobile && <Image src={BankRus} />}
          <div className={s.footer_text}>
            <p>
              Акция «Забота сближает». Период проведения акции: с 01.09.2024 по 30.11.2024. Информацию об организаторе
              акции, правилах ее проведения, призах, сроках, месте и порядке их получения можно узнать на сайте
              wad2024.ru.{' '}
              <Link onClick={handleClick} target='_blank' to={sberSpasibo}>
                Полные правила акции
              </Link>
            </p>
            <p>
              Акция «Забота сближает. 1 рубль с пачки — в приюты». Период проведения акции с 01.09.2024 по 31.12.2024.
              Информацию об организаторе акции, правилах её проведения, условиях перечисления денежных средств в
              благотворительный фонд можно узнать на сайте wad2024.ru.{' '}
              <Link onClick={handleClick} target='_blank' to={rules}>
                Полные правила акции
              </Link>
            </p>
            <p>
              В процессе игры применяется технология искусственного интеллекта. Результаты игры не являются
              окончательными, не являются сделкой по приобретению питомца и не предоставляют какого-либо
              преимущественного права по приобретению питомца. Администрация Сайта не является участником, организатором
              сделок, продавцом, представителем приюта, выгодоприобретателем или иным заинтересованным лицом в отношении
              сделки по приобретению питомца. Администрация Сайта не несет ответственности за результаты игры, а также
              не является владельцем и не несет ответственность за информацию, представленную приютом для генерации
              результатов игры с использованием технологии искусственного интеллекта. Участники игры используют
              информацию, чтобы заключать сделки на свой риск без прямого или косвенного участия или контроля со стороны
              Администрации Сайта. Порядок приобретения питомца определяется приютом самостоятельно.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
