const SelectLoveIcon = () => {
  return (
    <svg viewBox='3 0 172 121' fill='none' xmlnsSvg='http://www.w3.org/2000/svg'>
      <defs>
        <filter
          id='filter_883_89_dd'
          x='78.000000'
          y='0.000000'
          width='99.000000'
          height='124.000000'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='3.33333' result='effect_layerBlur_1' />
        </filter>
        <filter
          id='filter_883_91_dd'
          x='0.000000'
          y='0.000000'
          width='98.000000'
          height='124.000000'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='3.33333' result='effect_layerBlur_1' />
        </filter>
        <clipPath id='clip883_93'>
          <rect
            id='Frame 1077241591'
            rx='-0.500000'
            width='78.000000'
            height='103.000000'
            transform='translate(88.500000 10.500000)'
            fill='white'
            fillOpacity='0'
          />
        </clipPath>
        <clipPath id='clip883_95'>
          <rect
            id='Frame 1077241590'
            rx='-0.500000'
            width='77.000000'
            height='103.000000'
            transform='translate(10.500000 10.500000)'
            fill='white'
            fillOpacity='0'
          />
        </clipPath>
      </defs>
      <g filter='url(#filter_883_89_dd)'>
        <path
          id='Vector 1'
          d='M144.84 17.34C116.32 4.01 95.16 18.73 88.14 27.75L88.14 112C101.33 106.9 121.69 96.72 141.37 83.76C161.04 70.8 180.48 34 144.84 17.34Z'
          fill='#FFFF22'
          fillOpacity='1.000000'
          fillRule='nonzero'
        />
        <path
          id='Vector 1'
          d='M144.84 17.34C180.48 34 161.04 70.8 141.37 83.76C121.69 96.72 101.33 106.9 88.14 112L88.14 27.75C95.16 18.73 116.32 4.01 144.84 17.34Z'
          stroke='#FFFF22'
          strokeOpacity='1.000000'
          strokeWidth='4.000000'
        />
      </g>
      <g filter='url(#filter_883_91_dd)'>
        <path
          id='Vector 2'
          d='M31.84 17.34C60.35 4.01 81.52 18.73 88.54 27.75L88.54 112C75.35 106.9 54.98 96.72 35.31 83.76C15.64 70.8 -3.8 34 31.84 17.34Z'
          fill='#F129CC'
          fillOpacity='1.000000'
          fillRule='nonzero'
        />
        <path
          id='Vector 2'
          d='M31.84 17.34C-3.8 34 15.64 70.8 35.31 83.76C54.98 96.72 75.35 106.9 88.54 112L88.54 27.75C81.52 18.73 60.35 4.01 31.84 17.34Z'
          stroke='#F129CC'
          strokeOpacity='1.000000'
          strokeWidth='4.000000'
        />
      </g>
      <g clipPath='url(#clip883_93)'>
        <path
          id='Vector 1'
          d='M144.84 17.34C116.32 4.01 95.16 18.73 88.14 27.75L88.14 112C101.33 106.9 121.69 96.72 141.37 83.76C161.04 70.8 180.48 34 144.84 17.34Z'
          fill='#D9D9D9'
          fillOpacity='1.000000'
          fillRule='nonzero'
        />
        <path
          id='Vector 1'
          d='M144.84 17.34C180.48 34 161.04 70.8 141.37 83.76C121.69 96.72 101.33 106.9 88.14 112L88.14 27.75C95.16 18.73 116.32 4.01 144.84 17.34Z'
          stroke='#FFFF22'
          strokeOpacity='1.000000'
          strokeWidth='4.000000'
        />
      </g>
      <g clipPath='url(#clip883_95)'>
        <path
          id='Vector 2'
          d='M31.84 17.34C60.35 4.01 81.52 18.73 88.54 27.75L88.54 112C75.35 106.9 54.98 96.72 35.31 83.76C15.64 70.8 -3.8 34 31.84 17.34Z'
          fill='#D9D9D9'
          fillOpacity='1.000000'
          fillRule='nonzero'
        />
        <path
          id='Vector 2'
          d='M31.84 17.34C-3.8 34 15.64 70.8 35.31 83.76C54.98 96.72 75.35 106.9 88.54 112L88.54 27.75C81.52 18.73 60.35 4.01 31.84 17.34Z'
          stroke='#F129CC'
          strokeOpacity='1.000000'
          strokeWidth='4.000000'
        />
      </g>
      <mask id='mask_883_97' fill='white'>
        <path
          id='Union'
          d='M88.3428 27.5019C81.1252 18.4578 60.0876 4.13905 31.8438 17.344C-3.79688 34.0072 15.6436 70.805 35.3152 83.7652C54.7871 96.5938 74.9395 106.701 88.1406 111.843L88.1406 112C88.2078 111.974 88.2751 111.948 88.3428 111.922C88.4102 111.948 88.4775 111.974 88.5449 112L88.5449 111.843C101.746 106.701 121.898 96.5938 141.37 83.7652C161.042 70.805 180.482 34.0072 144.842 17.344C116.598 4.13905 95.5601 18.4578 88.3428 27.5019Z'
          clipRule='evenodd'
          fill=''
          fillOpacity='1.000000'
          fillRule='evenodd'
        />
      </mask>
      <path
        id='Union'
        d='M88.3428 27.5019C81.1252 18.4578 60.0876 4.13905 31.8438 17.344C-3.79688 34.0072 15.6436 70.805 35.3152 83.7652C54.7871 96.5938 74.9395 106.701 88.1406 111.843L88.1406 112C88.2078 111.974 88.2751 111.948 88.3428 111.922C88.4102 111.948 88.4775 111.974 88.5449 112L88.5449 111.843C101.746 106.701 121.898 96.5938 141.37 83.7652C161.042 70.805 180.482 34.0072 144.842 17.344C116.598 4.13905 95.5601 18.4578 88.3428 27.5019Z'
        clipRule='evenodd'
        fill='#FFFFFF'
        fillOpacity='1.000000'
        fillRule='evenodd'
        mask='url(#mask_883_97)'
      />
      <path id='Union' d='' clipRule='evenodd' fill='#FFFFFF' fillOpacity='1.000000' fillRule='evenodd' />
    </svg>
  )
}

export default SelectLoveIcon
