export const questionAnswer = [
  {
    question: 'Территория проведения Акции? ',
    description: [
      {
        title: 'Забота сближает. 1 рубль с пачки — в приюты',
        description:
          'Акция проводится на территории субъектов Российской Федерации, полный список которых приведен в Правилах акции.',
      },
      {
        title: 'Забота сближает',
        description:
          'Акция проводится на территории субъектов Российской Федерации, полный список которых приведен в Правилах акции.',
      },
    ],
  },

  {
    question: 'Сроки и время проведение Акции?',
    description: [
      {
        title: 'Забота сближает. 1 рубль с пачки — в приюты',
        description: `Общий срок проведения Акции: с 01 сентября 2024 года по 31 декабря 2024 года включительно.
Период участия (совершения покупки Продукта): с 01 сентября 2024 года по 31 октября 2024 года включительно.
Период перечисления денежных средств благотворительному фонду: с 01 ноября 2024 года по 31 декабря 2024 года включительно.`,
      },
      {
        title: 'Забота сближает',
        description: `Акция проводится в период c 01.09.2024 по 30.11.2024 включительно (включая период зачисления Поощрений).
Период совершения Участником Акции активных действий, указанных в п.5 Правил Акции для получения Поощрений Акции: с 01.09.2024 по 31.10.2024 включительно и/или до момента исчерпания фонда Акции (в зависимости от того, какое событие наступит ранее).`,
      },
    ],
  },

  {
    question: 'Кто может принять участие в Акции? ',
    description: [
      {
        title: 'Забота сближает. 1 рубль с пачки — в приюты',
        description: `В Акции могут принять участие находящиеся на территории Российской Федерации торговые сети, а также дистрибьюторы ООО «Марс» (ИНН 5045016560), с которыми у ООО «Марс» заключены договоры поставки Продукции. Потребители, совершая покупки в торговых сетях, влияют на увеличения объемов закупок торговыми сетями, что увеличивает помощь фонду.`,
      },
      {
        title: 'Забота сближает',
        description:
          'Принять участие в Акции вправе Участники Программы – Держатели Карт Банка и/или владельцы Платёжного счёта, достигшие 18 лет, являющиеся дееспособными, проживающие на территории Российской Федерации. Подробная информация о Карте Банка размещена на Сайте Банка (<a href="https://www.sberbank.ru" target="_blank">https://www.sberbank.ru</a>).',
      },
    ],
  },

  {
    question: 'Какая продукция участвует в Акции?',
    description: [
      {
        title: '',
        description:
          'Акция проводится в отношении продукции для кошек и собак, кроме продуктов с надписями «не для продажи», а также «продукт предназначен для бесплатного распространения» на упаковке, под товарными знаками WHISKAS®, SHEBA®, PERFECT FIT™, NATURE’S TABLE™, DREAMIES™, KITEKAT™, PEDIGREE®, CESAR®, CHAPPI™  ',
      },
    ],
  },

  {
    question: 'Как принять участие в Акции?',
    description: [
      {
        title: 'Забота сближает. 1 рубль с пачки — в приюты',
        description: `Для того, чтобы принять участие в Акции «Забота сближает. 1 рубль с пачки - в приюты» с целью направления денежных средств с покупки Продуктов в качестве пожертвования благотворительному фонду необходимо в период участия в Акции приобрести Продукцию WHISKAS®, SHEBA®, PERFECT FIT™, NATURE’S TABLE™, DREAMIES™, KITEKAT™, PEDIGREE®, CESAR®, CHAPPI™  у ООО «Марс» на любую сумму.`,
      },
      {
        title: 'Забота сближает',
        description: `Для получения Поощрения Участнику Акции необходимо:
        <br/>
1) Ознакомиться с Правилами Акции в Карточке Акции (по ссылке <a href='https://spasibosberbank.ru/partners/stocks/zabota-sblizhaet' target="_blank">https://spasibosberbank.ru/partners/stocks/zabota-sblizhaet</a>) 
или авторизоваться  по ссылке <a href='https://wad2024.ru' target="_blank">https://wad2024.ru</a> и ознакомиться с Правилами Акции на Сайте Акции <br/>
2) В период проведения  Акции Приобрести продукцию под товарными знаками WHISKAS®, SHEBA®, PERFECT FIT™, NATURE’S TABLE™, DREAMIES™, KITEKAT™, PEDIGREE®, CESAR®, CHAPPI™ с использованием Инструментов доступа по Счёту Карты Банка и/или Платежному счёту.`,
      },
    ],
  },

  {
    question: 'В каких магазинах (ритейлерах), я могу купить продукцию, участвующую в акции? ',
    description: [
      {
        title: 'Забота сближает. 1 рубль с пачки — в приюты',
        description: `Потребитель может приобрести акционный товар в любом магазине на территории РФ, где в ассортименте представлена продукция, участвующая в Акции.`,
      },
      {
        title: 'Забота сближает',
        description:
          'Вы можете купить продукцию в любом магазине на территории РФ, где в ассортименте представлена наша продукция, за исключением сервисов Общества с ограниченной ответственностью «Вайлдберриз» (ОГРН 1067746062449), Общества с ограниченной ответственностью «Яндекс» (ОГРН 1027700229193), Общества с ограниченной ответственностью «Интернет Решения» (ОГРН 1027739244741). С более подробной информации можете ознакомиться в Правилах Акции.',
      },
    ],
  },

  {
    question: 'Какому фонду будут пожертовованы собранные в рамках Акции средства?',
    description: [
      {
        title: 'Забота сближает. 1 рубль с пачки — в приюты',
        description:
          'Собранные в рамках Акции денежные средства будут переданы в качестве пожертвования благотворительному фонду «Банк еды "Русь"».',
      },
    ],
  },

  {
    question: 'Как еще я могу помочь фонду, участвующему в акции? ',
    description: [
      {
        title: 'Забота сближает. 1 рубль с пачки — в приюты',
        description: `С деятельностью Благотворительного фонда «Банк еды "Русь"» вы можете ознакомиться самостоятельно на сайте.
 ПЕРЕЙТИ НА САЙТ фонда: <a href='https://foodbankrus.ru/' target="_blank">foodbankrus.ru</a>
 `,
      },
    ],
  },

  {
    question: 'Нужно ли сохранять чек? ',
    description: [
      {
        title: 'Забота сближает',
        description:
          'Участник обязан сохранить оригинал документа, подтверждающего совершение покупки Товара , а также фотографии приобретённого Товара.',
      },
    ],
  },

  {
    question: 'Есть ли ограничения по количеству зарегистрированных чеков?  ',
    description: [
      {
        title: 'Забота сближает',
        description: 'Чеки в рамках данной Акции не регистрируются.',
      },
    ],
  },

  {
    question: `Какие призы участвуют в Акции? \n Какое количество призов?`,
    description: [
      {
        title: 'Забота сближает',
        description: `Призовой фонд Акции ограничен и включает в себя следующий вид приза:
Поощрение на бонусный счёт участника Акции, совершившего покупку товаров Акции каждый раз начисляются бонусы в размере 10% (десять процентов) от стоимости 1 (одного) или более товаров Акции.`,
      },
    ],
  },

  {
    question: 'Как узнать, стал ли я обладателем Приза Акции?',
    description: [
      {
        title: 'Забота сближает',
        description:
          'Участник должен самостоятельно ежедневно отслеживать баланс бонусного счета Программы «СберСпасибо». ',
      },
    ],
  },

  {
    question: 'Сколько призов я могу получить?',
    description: [
      {
        title: 'Забота сближает',
        description:
          'Размер поощрений в рамках Акции не может превышать 400 бонусов «СберСпасибо» на одного участника Акции.',
      },
    ],
  },

  {
    question: 'В течение какого срока я могу воспользоваться кешбэком?',
    description: [
      {
        title: 'Забота сближает',
        description:
          'Использование бонусов осуществляется участниками Акции в порядке и на условиях, предусмотренных правилами Программы «СберСпасибо»',
      },
    ],
  },

  {
    question: 'Когда я смогу получить свой Приз?',
    description: [
      {
        title: 'Забота сближает',
        description:
          'Бонусные баллы в размере 10% от стоимости одного или более товаров  (но не более  400 Бонусов на одного Участника Акции) начисляются Оператором Акции (АО «Центр программ лояльности») на Бонусный счёт Участника до 30 ноября 2024 г. включительно.',
      },
    ],
  },

  {
    question: 'Кто является Организатором Акции? ',
    description: [
      {
        title: '',
        description: 'Организатором Акции является Общество с Ограниченной Ответственностью «ТМА Маркетинг Сервисез».',
      },
    ],
  },

  {
    question: 'С кем я могу связаться по вопросам получения приза?',
    description: [
      {
        title: 'Забота сближает',
        description:
          'По вопросам, связанным с не начислением бонусов в соответствии с условиями правил Акции, участник вправе обратиться в контактный центр Программы «СберСпасибо», указав данные, которые Участник сообщал при присоединении к Программе.',
      },
    ],
  },

  {
    question: 'К кому я могу обратиться по другим вопросам об Акции?',
    description: [
      {
        title: '',
        description:
          'Все свои вопросы Вы можете адресовать организатору Акции на электронную почту wad@tma-draft.com. ',
      },
    ],
  },

  {
    question: 'Где я могу узнать подробные Правила Акции? ',
    description: [
      {
        title: 'Забота сближает. 1 рубль с пачки — в приюты',
        description: 'С полными правилами Акции можете ознакомиться по ссылке.',
      },
      {
        title: 'Забота сближает',
        description: 'С полными правилами Акции можете ознакомиться по ссылке.',
      },
    ],
  },
]
