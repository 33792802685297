const AnswerIcon = () => {
  return (
    <svg
      width='87.214844'
      height='100.632629'
      viewBox='0 0 87.2148 100.633'
      fill='none'
      xmlnsSvg='http://www.w3.org/2000/svg'
    >
      <defs />
      <mask
        id='mask883_257'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='15.414062'
        y='19.592285'
        width='53.591797'
        height='56.858826'
      >
        <rect
          id='Rectangle 81'
          x='15.414062'
          y='19.592285'
          rx='5.750436'
          width='53.591648'
          height='56.858841'
          fill='#D9D9D9'
          fill-opacity='1.000000'
        />
      </mask>
      <g mask='url(#mask883_257)'>
        <path
          id='Polygon 2'
          d='M41.05 22.93L25.84 31.57C24.04 32.59 22.93 34.5 22.93 36.57L22.93 53.7C22.93 55.77 24.04 57.68 25.84 58.7L41.05 67.35C42.81 68.35 44.97 68.35 46.73 67.35L61.94 58.7C63.74 57.68 64.85 55.77 64.85 53.7L64.85 36.57C64.85 34.5 63.74 32.59 61.94 31.57L46.73 22.93C44.97 21.93 42.81 21.93 41.05 22.93Z'
          fill='#62196D'
          fill-opacity='0'
          fill-rule='evenodd'
        />
        <path
          id='Polygon 2'
          d='M25.84 31.57C24.04 32.59 22.93 34.5 22.93 36.57L22.93 53.7C22.93 55.77 24.04 57.68 25.84 58.7L41.05 67.35C42.81 68.35 44.97 68.35 46.73 67.35L61.94 58.7C63.74 57.68 64.85 55.77 64.85 53.7L64.85 36.57C64.85 34.5 63.74 32.59 61.94 31.57L46.73 22.93C44.97 21.93 42.81 21.93 41.05 22.93L25.84 31.57Z'
          fill='#B022C6'
          fill-opacity='1.000000'
          fill-rule='evenodd'
        />
      </g>
      <g opacity='0.700000'>
        <mask
          id='mask883_260'
          mask-type='alpha'
          maskUnits='userSpaceOnUse'
          x='4.791992'
          y='13.084656'
          width='69.963867'
          height='71.254761'
        >
          <rect
            id='Rectangle 81'
            x='4.791992'
            y='13.084656'
            rx='9.584060'
            width='69.963638'
            height='71.254768'
            fill='#D9D9D9'
            fill-opacity='1.000000'
          />
        </mask>
        <g mask='url(#mask883_260)'>
          <path
            id='Polygon 2'
            d='M38.94 18.05L22.57 27.48C19.61 29.19 17.78 32.36 17.78 35.78L17.78 54.62C17.78 58.05 19.61 61.21 22.57 62.92L38.94 72.36C41.9 74.06 45.55 74.06 48.51 72.36L64.87 62.92C67.84 61.21 69.67 58.05 69.67 54.62L69.67 35.78C69.67 32.36 67.84 29.19 64.87 27.48L48.51 18.05C45.55 16.34 41.9 16.34 38.94 18.05Z'
            fill='#62196D'
            fill-opacity='0'
            fill-rule='evenodd'
          />
          <path
            id='Polygon 2'
            d='M22.57 27.48C19.61 29.19 17.78 32.36 17.78 35.78L17.78 54.62C17.78 58.05 19.61 61.21 22.57 62.92L38.94 72.36C41.9 74.06 45.55 74.06 48.51 72.36L64.87 62.92C67.84 61.21 69.67 58.05 69.67 54.62L69.67 35.78C69.67 32.36 67.84 29.19 64.87 27.48L48.51 18.05C45.55 16.34 41.9 16.34 38.94 18.05L22.57 27.48ZM43.67 45.21L43.67 45.2L43.72 45.23L43.78 45.2L43.78 45.21L43.72 45.18L43.67 45.21Z'
            fill='#B022C6'
            fill-opacity='1.000000'
            fill-rule='evenodd'
          />
        </g>
      </g>
      <g opacity='0.400000'>
        <mask
          id='mask883_263'
          mask-type='alpha'
          maskUnits='userSpaceOnUse'
          x='6.709473'
          y='5.892273'
          width='74.755859'
          height='87.073273'
        >
          <rect
            id='Rectangle 81'
            x='6.709473'
            y='5.892273'
            rx='14.376090'
            width='74.755661'
            height='87.073273'
            fill='#D9D9D9'
            fill-opacity='1.000000'
          />
        </mask>
        <g mask='url(#mask883_263)'>
          <path
            id='Polygon 2'
            d='M36.55 12.83L19.37 22.74C14.92 25.31 12.18 30.06 12.18 35.19L12.18 55.01C12.18 60.15 14.92 64.89 19.37 67.46L36.55 77.37C40.99 79.94 46.47 79.94 50.92 77.37L68.09 67.46C72.54 64.89 75.28 60.15 75.28 55.01L75.28 35.19C75.28 30.06 72.54 25.31 68.09 22.74L50.92 12.83C46.47 10.26 40.99 10.26 36.55 12.83Z'
            fill='#62196D'
            fill-opacity='0'
            fill-rule='evenodd'
          />
          <path
            id='Polygon 2'
            d='M19.37 22.74C14.92 25.31 12.18 30.06 12.18 35.19L12.18 55.01C12.18 60.15 14.92 64.89 19.37 67.46L36.55 77.37C40.99 79.94 46.47 79.94 50.92 77.37L68.09 67.46C72.54 64.89 75.28 60.15 75.28 55.01L75.28 35.19C75.28 30.06 72.54 25.31 68.09 22.74L50.92 12.83C46.47 10.26 40.99 10.26 36.55 12.83L19.37 22.74ZM38.07 41.84L38.07 48.36L43.73 51.63L49.39 48.36L49.39 41.84L43.73 38.58L38.07 41.84Z'
            fill='#B022C6'
            fill-opacity='1.000000'
            fill-rule='evenodd'
          />
        </g>
      </g>
      <g opacity='0.100000'>
        <mask
          id='mask883_266'
          mask-type='alpha'
          maskUnits='userSpaceOnUse'
          x='0.000000'
          y='0.000000'
          width='87.214844'
          height='100.632629'
        >
          <rect
            id='Rectangle 81'
            rx='19.168119'
            width='87.214951'
            height='100.632637'
            fill='#D9D9D9'
            fill-opacity='1.000000'
          />
        </mask>
        <g mask='url(#mask883_266)'>
          <path
            id='Polygon 2'
            d='M34.06 8.83L16.94 18.75C11.02 22.18 7.37 28.5 7.37 35.34L7.37 55.28C7.37 62.12 11.02 68.44 16.94 71.87L34.06 81.79C40 85.23 47.33 85.23 53.28 81.79L70.4 71.87C76.32 68.44 79.96 62.12 79.96 55.28L79.96 35.34C79.96 28.5 76.32 22.18 70.4 18.75L53.28 8.83C47.33 5.39 40 5.39 34.06 8.83Z'
            fill='#62196D'
            fill-opacity='0'
            fill-rule='evenodd'
          />
          <path
            id='Polygon 2'
            d='M16.94 18.75C11.02 22.18 7.37 28.5 7.37 35.34L7.37 55.28C7.37 62.12 11.02 68.44 16.94 71.87L34.06 81.79C40 85.23 47.33 85.23 53.28 81.79L70.4 71.87C76.32 68.44 79.96 62.12 79.96 55.28L79.96 35.34C79.96 28.5 76.32 22.18 70.4 18.75L53.28 8.83C47.33 5.39 40 5.39 34.06 8.83L16.94 18.75ZM33.27 39.22L33.27 51.41L43.67 57.43L54.07 51.41L54.07 39.22L43.67 33.19L33.27 39.22Z'
            fill='#B022C6'
            fill-opacity='1.000000'
            fill-rule='evenodd'
          />
        </g>
      </g>
    </svg>
  )
}

export default AnswerIcon
